<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('fair_report.ledger_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col sm="4">
            <b-form-group
              label-for="year"
            >
              <template v-slot:label>
                {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                id="year"
                :options="yearList"
                v-model="search.year"
              >
                <!-- <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template> -->
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label-for="fair_id"
            >
              <template v-slot:label>
                {{ $t('tradeFairConfig.fair_name') }}
              </template>
              <b-form-select
                plain
                id="fair_id"
                :options="dhakaFairList"
                v-model="search.fair_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <ValidationProvider name="Stall Category" vid="stall_category" v-slot="{ errors }">
            <b-form-group
              label-for="stall_category"
            >
              <template v-slot:label>
                {{ $t('fair_report.space_category') }}
              </template>
              <b-form-select
                plain
                id="stall_category"
                :options="stallCategoryList"
                v-model="search.stall_category"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                  {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label-for="stall_no"
            >
              <template v-slot:label>
                {{ $t('fair_report.stall_no') }}
              </template>
              <b-form-select
                plain
                id="stall_no"
                :options="stallList"
                v-model="search.stall_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" style="padding-top: 15px">
            <b-button size="sm" variant="primary" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('fair_report.ledger_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button v-if="isResult && finalList.length === undefined" variant="primary" size="sm" @click="pdfExport" class="float-right">
            {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
            <template v-slot:projectNameSlot>
              {{ }}
            </template>
            {{ $t('fair_report.application_report') }}
          </list-report-head>
          <b-row class="mb-2">
            <b-col md="6">
              <span>{{ $t('globalTrans.year') }}</span>:
              <strong>{{ parseInt(search_param.year) ? $n(search_param.year, {useGrouping: false}) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="6" class="text-right">
              <span>{{ $t('tradeFairConfig.fair_name') }}</span>:
              <strong>{{ parseInt(search.fair_id) ? getSearchFairName(search.fair_id) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="6">
              <span>{{ $t('fair_report.space_category') }}</span>:
              <strong>{{ search_param.stall_category ? getColumnName($store.state.TradeFairService.ditfCommonObj.stallCategoryList, search_param.stall_category) : $t('globalTrans.all') }}</strong>
            </b-col>
            <b-col md="6">
              <span>{{ $t('fair_report.stall_no') }}</span>:
              <strong>{{ search_param.stall_id ? getColumnName(stallList, search_param.stall_id, true) : $t('globalTrans.all') }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-overlay>
              <b-row>
                <b-col lg="12" sm="12">
                  <table style="width:100%;">
                    <thead>
                      <tr>
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('globalTrans.application_id') }}</th>
                        <th>{{ $t('exportTrophy.org_name_address') }}</th>
                        <th>{{ $t('exportTrophy.applicant_name_mobile') }}</th>
                        <th style="width: 25% !important">{{ $t('exportTrophy.deposit_datails') }}</th>
                        <th style="width: 18% !important">{{ $t('externalTradeFair.refunded_description') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="finalList.length > 0">
                      <tr v-for="(item, index) in finalList" :key="index">
                        <td class="text-center"> {{$n(index + 1)}}</td>
                        <td><div style="font-size: 16px">{{ item.app_auto_id }}</div></td>
                        <td>
                          <b>{{ currentLocale === 'bn' ? item.comp_name_bn : item.comp_name_en }}<br/></b>
                          <span v-if="item.country_type === 1">
                            {{ AddressHelper.getCommonAddress(item) + ', ' + getCountry(item.country_id) }}
                          </span>
                          <span v-else>
                            {{ AddressHelper.getCommonAddress(item) + (currentLocale === 'bn' ? item.city_bn : item.city_en) + ', ' + (currentLocale === 'bn' ? item.state_bn : item.state_en) + ', ' + getCountry(item.country_id)}}
                          </span>
                        </td>
                        <td>
                          {{ currentLocale === 'bn' ? item.name_bn : item.name_en }}
                          {{ item.mobile_no | mobileNumber }}
                        </td>
                        <td>
                          <b-table-simple borderless striped hover small caption-top responsive>
                            <tr>
                              <th style="text-align:center">{{ $t('externalTradeFair.security_money') }}</th>
                            </tr>
                            <tr>
                              <td style="text-align:left">
                                <div style="font-size: 15px;">
                                  {{ $t('ditfConfig.category') }} : {{ securityCatInfo(item.stall_pavilions) }}<br/>
                                  {{ $t('globalTrans.transaction_no') }} : {{ item.app_payment.transaction_no }}<br/>
                                  {{ $t('associationPnl.pay_date') }} : {{ item.app_payment.pay_date | dateFormat }}<br/>
                                  <b>{{ $t('globalTrans.amount') }} : {{ $n(amountReturnStall(item.stall_pavilions)) }}</b>
                                </div>
                              </td>
                            </tr>
                            <slot v-if="item.app_stall_payment.length > 0">
                              <tr>
                                <th style="text-align:center">{{ $t('externalTradeFair.stall_payment') }}</th>
                              </tr>
                              <tr>
                                <td style="text-align:left">
                                  <div style="font-size: 15px;">
                                    {{ $t('fair_report.stall_no') }} : {{ transStallInfo(item.app_stall_payment) }}<br/>
                                    {{ $t('globalTrans.transaction_no') }} : {{ transNoReturn(item.app_stall_payment) }}<br/>
                                    {{ $t('associationPnl.pay_date') }} : {{ stallPaymentDate(item.app_stall_payment) }}<br/>
                                    <b>{{ $t('globalTrans.amount') }} : {{ $n(amountReturn(item.app_stall_payment)) }}</b>
                                  </div>
                                </td>
                              </tr>
                            </slot>
                          </b-table-simple>
                          <b>{{ $t('eBizProgram.grand_total') }} : {{ $n(parseInt(amountReturnStall(item.stall_pavilions)) + parseInt(amountReturn(item.app_stall_payment))) }}</b>
                        </td>
                        <td style="text-align:left">
                          <slot v-if="item.app_refund.length > 0">
                            <div style="font-size: 15px;">
                              {{ $t('ditfConfig.category') }} : {{ refundCatInfo(item.app_refund) }}<br/>
                              {{ $t('externalTradeFair.refunded_date') }} : {{ stallPaymentDate(item.app_refund, true) }}<br/>
                              <b>{{ $t('globalTrans.amount') }} : {{ $n(amountReturn(item.app_refund, true)) }}</b>
                            </div>
                          </slot>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="8" class="text-center"> {{ $t('fair_report.not_found') }} </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-overlay>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { ditfApplicationTransReport, getMasterStall } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import ModalBaseMasterList from '@/mixins/list'
import { dateFormat } from '@/utils/fliter'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  data () {
    return {
      AddressHelper: AddressHelper,
      baseUrl: internationalTradeFairServiceBaseUrl,
      sortBy: '',
      detailsItemId: '',
      isResult: false,
      stallMaster: [],
      itemData: [],
      finalList: [],
      dhakaFairList: [],
      stallList: [],
      search: {
        year: 0,
        fair_id: 0,
        stall_category: 0,
        stall_id: 0,
        circular_type: 1
      },
      search_param: {
        year: 0,
        fair_id: 0,
        circular_type: 0
      },
      statusList: [
        {
          value: 1,
          text: 'Pending',
          text_en: 'Pending',
          text_bn: 'বিচারাধীন'
        },
        {
          value: 2,
          text: 'Recommended',
          text_en: 'Recommended',
          text_bn: 'সুপারিশকৃত'
        },
        {
          value: 3,
          text: 'Rejected',
          text_en: 'Rejected',
          text_bn: 'প্রত্যাখ্যাত'
        },
        {
          value: 4,
          text: 'Return',
          text_en: 'Return',
          text_bn: 'প্রত্যাবর্তন'
        },
        {
          value: 5,
          text: 'Resubmit',
          text_en: 'Resubmit',
          text_bn: 'আবার জমা দিন'
        }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    this.search_param.year = this.search.year
    this.loadData()
  },
  watch: {
    'search.year': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dhakaFairList = this.dhakaFair()
      }
    },
    'search.stall_category': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.stallList = this.getStall(newVal)
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  computed: {
    stallCategoryList () {
      return this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.EngBangNum(item.toString()) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getColumnName (list, groupId, otherVal = false) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (otherVal) {
          return obj.text
        } else {
          if (this.$i18n.locale === 'bn') {
            return obj.text_bn
          } else {
            return obj.text_en
          }
        }
      } else {
          return ''
      }
    },
    async getStall (stallCat) {
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, getMasterStall + '/' + stallCat)
      if (result.success) {
        this.stallList = result.data
      } else {
        this.stallList = []
      }
    },
    transStallInfo (data) {
      const dataVal = []
      data.forEach((item, key) => {
        const dataFind = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(dt => dt.value === parseInt(item.stall_cat_id))
        const stall = this.stallMaster.find(dt => dt.id === parseInt(item.stall_id))
        if (this.$i18n.locale === 'bn') {
          const stallData = stall.stall_no + '(' + dataFind.text_bn + ')'
          dataVal.push(stallData)
        } else {
          const stallData = stall.stall_no + '(' + dataFind.text_en + ')'
          dataVal.push(stallData)
        }
      })
      return dataVal.join(',')
    },
    securityCatInfo (data) {
      const dataVal = []
      data.forEach((item, key) => {
        const dataFind = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(dt => dt.value === parseInt(item.stall_cat_id))
         if (this.$i18n.locale === 'bn') {
         dataVal.push(dataFind.text_bn)
        } else {
          dataVal.push(dataFind.text_en)
        }
      })
      return dataVal.join(',')
    },
    refundCatInfo (data) {
      const dataVal = []
      data.forEach((item, key) => {
        item.stall_cat_ids.forEach((item1, key1) => {
          const dataFind = this.$store.state.TradeFairService.ditfCommonObj.stallCategoryList.find(dt => dt.value === parseInt(item1))
          if (this.$i18n.locale === 'bn') {
            dataVal.push(dataFind.text_bn)
          } else {
            dataVal.push(dataFind.text_en)
          }
        })
      })
      return dataVal.join(',')
    },
    stallPaymentDate (data, type = false) {
      const stallDate = []
      data.forEach((item, key) => {
        if (type) {
          stallDate.push(dateFormat(item.refund_date))
        } else {
          stallDate.push(dateFormat(item.pay_date))
        }
      })
      return stallDate.join(', ')
    },
    transNoReturn (data) {
      const transNo = []
      data.forEach((item, key) => {
        transNo.push(item.transaction_no)
      })
      return transNo.join(', ')
    },
    amountReturn (data, type = false) {
      let amount = 0
      data.forEach((item, key) => {
        if (type) {
          amount += parseInt(item.total_refund)
        } else {
          amount += parseInt(item.payment_amount)
        }
      })
      return amount
    },
    amountReturnStall (data) {
      let amount = 0
      data.forEach((item, key) => {
        amount += parseInt(item.quoted_price * 0.25)
      })
      return amount
    },
    circularType (fair) {
      const sectorObj = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.find(el => el.value === parseInt(fair))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getCountry (id) {
      const sectorObj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getStatus (id) {
      const sectorObj = this.statusList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getSearchParamName (Id, paramName) {
      let arrayData
      if (paramName === 'cType') {
        arrayData = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList
      } else {
        return ''
      }
      const Obj = arrayData.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
      } else {
        return ''
      }
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, ditfApplicationTransReport, this.search)
      if (result.success) {
        this.search_param = this.search
        this.finalList = result.data
        this.stallMaster = result.stallMaster
        this.isResult = true
      } else {
        this.finalList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getCompanyTypeName (id) {
      const companyType = this.$store.state.TradeFairService.commonObj.companyTypeList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, year: this.search.year, circular_type: this.search.circular_type, fair_id: this.search.fair_id })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, ditfApplicationTransReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
